(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/breadcrumbs/assets/javascripts/utils/breadcrumbs-utils.js') >= 0) return;  svs.modules.push('/components/lb-ui/breadcrumbs/assets/javascripts/utils/breadcrumbs-utils.js');
"use strict";


const constants = {
  SCROLL_MIN_DISTANCE_REQUIRED: 40
};

const throttle = function (func, wait) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let args;
  let result;
  let timeout = null;
  let previous = 0;
  const later = function () {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(this, args);
    if (!timeout) {
      args = null;
    }
  };
  return function () {
    const now = Date.now();
    if (!previous && options.leading === false) {
      previous = now;
    }
    const remaining = wait - (now - previous);
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(this, args);
      if (!timeout) {
        args = null;
      }
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
};
const shouldUseDynamicCrumb = (parentElement, isPreviouslyUsingDynamicCrumbs) => {
  if (!(parentElement instanceof Element)) {
    return false;
  }
  const viewHeight = window.innerHeight;
  const headerHeight = parentElement.offsetTop;
  let elementHeight = parentElement.offsetHeight;
  const elStaticBreadcrumb = parentElement.querySelector('.breadcrumb-for-mobile.static');
  if (!isPreviouslyUsingDynamicCrumbs && elStaticBreadcrumb) {
    const computedStyle = window.getComputedStyle(elStaticBreadcrumb);
    const marginHeight = parseFloat(computedStyle.marginTop) + parseFloat(computedStyle.marginBottom);
    elementHeight -= Math.ceil(elStaticBreadcrumb.offsetHeight + marginHeight);
  }
  const availableHeight = viewHeight - headerHeight;
  const heightAvailableByScroll = elementHeight - availableHeight;
  return heightAvailableByScroll > constants.SCROLL_MIN_DISTANCE_REQUIRED * 3;
};
const replaceWithDots = () => {
  const navHolder = document.querySelector('.slim-breadcrumbs');
  const links = document.querySelectorAll('.crumb a');
  const crumbs = document.querySelectorAll('.crumb');
  if (crumbs.length) {
    const clonedCrumb = crumbs[0].cloneNode(true);
    clonedCrumb.querySelector('a').replaceWith('...');
    if (links.length > 1) {
      for (let i = 0; i < links.length - 1; i++) {
        crumbs[i].remove();
      }
      document.querySelector('.crumb-first').after(clonedCrumb);
    }
  }
  navHolder.style.opacity = 1;
};
setGlobal('svs.components.lbUi.Breadcrumbs', {
  utils: {
    replaceWithDots,
    shouldUseDynamicCrumb,
    throttle
  },
  constants
});

 })(window);