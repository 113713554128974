(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/breadcrumbs/assets/javascripts/breadcrumb-for-mobile.js') >= 0) return;  svs.modules.push('/components/lb-ui/breadcrumbs/assets/javascripts/breadcrumb-for-mobile.js');
"use strict";


if (!svs.isServer) {
  const mainContentElement = document.querySelector('#main-content');
  const previousCrumbLink = mainContentElement.querySelector('.previous-crumb-link');

  if (previousCrumbLink) {
    const {
      shouldUseDynamicCrumb,
      throttle
    } = svs.components.lbUi.Breadcrumbs.utils;
    const {
      SCROLL_MIN_DISTANCE_REQUIRED
    } = svs.components.lbUi.Breadcrumbs.constants;
    let lastScrollY = window.scrollY;
    const onScrollEvent = element => {
      const currentScrollY = window.scrollY;
      const distanceScrolled = currentScrollY - lastScrollY;
      if (currentScrollY > lastScrollY) {
        element.classList.remove('visible');
      }
      if (currentScrollY < lastScrollY && Math.abs(distanceScrolled) > SCROLL_MIN_DISTANCE_REQUIRED) {
        element.classList.add('visible');
      }
      lastScrollY = currentScrollY <= 0 ? 0 : currentScrollY;
    };
    const handleScrollEvents = throttle(() => {
      onScrollEvent(previousCrumbLink);
    }, 300, {
      leading: true
    });
    const applyStaticCrumb = () => {
      window.removeEventListener('scroll', handleScrollEvents);
      previousCrumbLink.parentElement.classList.remove('use-visibiliy-by-scroll', 'use-transitions');
      previousCrumbLink.parentElement.classList.add('static');
    };
    const applyDynamicCrumb = () => {
      previousCrumbLink.parentElement.classList.remove('static');
      previousCrumbLink.parentElement.classList.add('use-visibiliy-by-scroll');
      setTimeout(() => {
        previousCrumbLink.parentElement.classList.add('use-transitions');
      }, 50);
      window.addEventListener('scroll', handleScrollEvents);
    };
    let useDynamicCrumbs = shouldUseDynamicCrumb(mainContentElement);
    const observer = new ResizeObserver(throttle(entries => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        useDynamicCrumbs = shouldUseDynamicCrumb(mainContentElement, useDynamicCrumbs);
        if (useDynamicCrumbs) {
          applyDynamicCrumb();
        } else {
          applyStaticCrumb();
        }
      });
    }, 600));
    observer.observe(mainContentElement);
  }

  if (document.querySelector('.slim-breadcrumbs')) {
    const {
      replaceWithDots
    } = svs.components.lbUi.Breadcrumbs.utils;
    replaceWithDots();
  }
}

 })(window);